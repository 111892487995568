<div class="page library">
  <app-section-top [title]="'academy.Discover_our_library'" [subtitle]="'academy.Feed_science_craving'|translate"
                   [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-library.jpg'"></app-section-top>
  <div class="content">
    <div class="library-content" *ngIf="!loadingUser">
      <div class="library-search">
        <div class="library-search-form">
          <div class="library-search-input">
            <input type="search" placeholder="{{'academy.Search'|translate}}"
                   (keyup)="searchQueryChange(searchQuery)" [(ngModel)]="searchQuery"/>
            <i class="fa fa-search library-search-submit" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="library-filters">
        <div class="row">
          <div class="library-filters-themes col-6 col-md-3" *ngIf="themes">
            <select id="theme"
                    name="theme"
                    class="library-filters-select"
                    [ngModel]="selectedTheme"
                    (ngModelChange)="themeChange($event)">
              <option [ngValue]="undefined">{{'academy.Filter_by_theme'|translate}}</option>
              <option *ngFor="let option of themes"
                      [ngValue]="option">
                {{translationService.getTranslatedName(option['translations'], locale)}}
              </option>
            </select>
          </div>
          <div class="library-filters-congress col-6 col-md-3" *ngIf="worldwideEvents">
            <select id="events"
                    name="events"
                    class="library-filters-select"
                    [(ngModel)]="selectedWorldwideEvent"
                    (ngModelChange)="worldwideEventChange($event)">
              <option [ngValue]="undefined">{{'academy.Filter_by_event'|translate}}</option>
              <option *ngFor="let option of worldwideEvents"
                      [ngValue]="option">
                {{ option.id === 5799 || option.id === 5801 ? 'Academy Webinars' : option['title'] }}
              </option>
            </select>
          </div>
          <div class="library-filters-selection col-6"
               *ngIf="!user?.premium_membership || ((currentTab === 'lectures' || currentTab === 'courses') && environment.domain == 'aesthetics')">
            <div class="form-check text-right pull-right">
              <h6>Selection</h6>


              <div class="custom-control custom-checkbox mr-sm-2 mb-sm-0"
                   *ngIf="user?.premium_membership">
                <input type="checkbox" id="can-access" name="canAccess"
                       class="custom-control-input" [checked]="canAccess"
                       (change)="toggleCanAccess($event.target.checked)">
                <label class="custom-control-label" for="can-access">{{'academy.Videos_I_can_access'|translate}}</label>
              </div>

              <div class="custom-control custom-checkbox mr-sm-2 mb-sm-0"
                   *ngIf="!user?.premium_membership">
                <input type="checkbox" id="free-selection" name="free-selection"
                       class="custom-control-input" [checked]="free"
                       (change)="toggleFree($event.target.checked)">
                <label class="custom-control-label" for="free-selection">{{'academy.Free_selection'|translate}}</label>
              </div>

              <div class="custom-control custom-checkbox mr-sm-2 mb-sm-0"
                   *ngIf="currentTab === 'lectures' && environment.domain == 'aesthetics'">
                <input type="checkbox" id="demo" name="demo" class="custom-control-input"
                       [checked]="demo" (change)="toggleDemo($event.target.checked)">
                <label class="custom-control-label"
                       for="demo">{{'academy.Filters_demo'|translate}}</label>
              </div>
   <!--           <div class="custom-control custom-checkbox mr-sm-2 mb-sm-0"
                   *ngIf="currentTab === 'courses' && environment.domain == 'aesthetics'">
                <input type="checkbox" id="certificate" name="certificate" class="custom-control-input"
                       [checked]="withCertificate"
                       (change)="toggleWithCertificate($event.target.checked)">
                <label class="custom-control-label"
                       for="certificate">{{ 'certificate.cme_accredited'|translate }}</label>
              </div>-->
            </div>
          </div>
          <div class="col-2">
            <button *ngIf="selectedTheme || selectedWorldwideEvent" class="button button-orange"
                    style="height: 40px;font-size: 14px;" (click)="clearFilter()" type="button">Clear Filter <!--COMING SOON : academy.clear_filter-->
            </button>
          </div>
        </div>
      </div>
      <div class="library-keywords" *ngIf="!environment.mobile && keywords.length>0">
        <h6 *ngIf="openKeywords" (click)="redirect(false); openKeywords = false;">{{'academy.close_keywords_panel'|translate}} <i class="fa fa-times" aria-hidden="true"
                                                            ></i></h6>
        <h6 *ngIf="!openKeywords" (click)="redirect(true); openKeywords = true">Open keywords panel <!--COMING SOON : academy.open_keywords_panel--><i class="fa fa-chevron-down" aria-hidden="true"
                                                            ></i></h6>
        <ul class="library-keywords-list" *ngIf="openKeywords">
          <li class="library-keywords-item" *ngFor="let keyword of keywords; index as i">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" [id]="'key-'+ i" name="certificate" class="custom-control-input"
                     (change)="toggleKeyword(keyword, $event.target.checked)" [checked]="keyword.checked">
              <label class="custom-control-label"
                     [for]="'key-'+ i">{{translationService.getTranslatedName(keyword.translations,
                locale)}}</label>
            </div>
          </li>
        </ul>
      </div>
      <div class="library-languages" *ngIf="languages.length>0">
        <h6>{{'academy.Filter_by_trad'|translate}}</h6>
        <ul class="library-languages-list">
          <li class="library-languages-item" *ngFor="let language of languages; index as i">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" (change)="toggleLanguage(language, $event.target.checked)"
                     [id]="'key-'+ language.locale" [checked]="language.checked" class="custom-control-input">
              <label class="custom-control-label" [for]="'key-'+ language.locale">{{ language.name}}</label>
            </div>
          </li>
        </ul>
      </div>
      <div class="library-worldwide-event"
           *ngIf="selectedWorldwideEvent && selectedWorldwideEvent.organizer_scientific_society_id && selectedWorldwideEvent.organizer_scientific_society_id != 60 && selectedWorldwideEvent.organizer_scientific_society_id != 61">
        <a href="{{selectedWorldwideEvent.website}}"><img
          src="{{ environment.cdn_base_url }}/scientific_societies/{{selectedWorldwideEvent.organizer_scientific_society_id}}.jpg"></a>
        <h6
          class="worldwide-event-title">{{'academy.Filter_description_event'|translate}} {{translationService.getTitleWithoutNb(selectedWorldwideEvent.title)}}</h6>
      </div>
      <div class="library-tabs">
        <ul class="tabs">
          <li class="tab" [ngClass]="{'active': currentTab === 'courses'}" (click)="selectTab('courses')">
            <a>{{'academy.courses'|translate}} ({{coursesHits}})</a>
          </li>
          <li class="tab" [ngClass]="{'active': currentTab === 'lectures'}" (click)="selectTab('lectures')">
            <a>{{'academy.Lectures'|translate}} ({{mediasHits}})</a>
          </li>
        </ul>
      </div>
     <!-- <div class="library-sort" *ngIf="currentTab === 'courses'">
        <h6>{{'academy.sort_by'|translate}}</h6>
        <div class="custom-control custom-radio mr-sm-2 mb-sm-0">
          <input type="radio" name="sortc" id="release-date" class="custom-control-input"
                 [checked]="currentCoursesIndex === 'courses'"
                 (change)="onSortCoursesChange('courses', $event.target.checked)">
          <label class="custom-control-label" for="release-date">{{'academy.Release_date'|translate}}</label>
        </div>
        <div class="custom-control custom-radio mr-sm-2 mb-sm-0">
          <input type="radio" name="sortc" id="popular" class="custom-control-input"
                 [checked]="currentCoursesIndex === 'courses_relevance_popular'"
                 (change)="onSortCoursesChange('courses_relevance_popular', $event.target.checked)">
          <label class="custom-control-label" for="popular">{{'academy.Popular'|translate}}</label>
        </div>
      </div>-->
      <div class="library-courses" *ngIf="currentTab === 'courses' && courses.length > 0" infiniteScroll
           [infiniteScrollDistance]="-0.1" [infiniteScrollThrottle]="300" (scrolled)="onCoursesScroll()">
        <div class="row">
          <ng-container *ngFor="let course of courses">
            <div class="course-item col-6 col-md-4"
                 *ngIf="!course.paid_course || (course.paid_course && course.isBoughtByAuth(user))">
              <app-course-element [user]="user" [course]="course"></app-course-element>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="library-load-more"
           *ngIf="showCoursesLoading === false && currentTab === 'courses' && courses.length > 0 && currentCoursesPage < coursesPages">
        <a class="library-load-more-button"
           (click)="autoscroll = !autoscroll; onCoursesScroll()">{{'academy.load_more'|translate}}
          <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
      </div>
      <div class="library-not-found"
           *ngIf="showCoursesLoading === false && currentTab === 'courses' && courses.length === 0">
        <!--  browse the library  -->
        <section class="library-not-found-section">
          <div class="library-not-found-content">
            <h2>
              <a [routerLink]="'/library/courses'">{{ 'academy.Browse_through'|translate }}<i
                class="fal fa-chevron-right"></i></a>
            </h2>
            <div class="slick-slider-previous-left slick-slider-arrow left-themes">
              <a class="icon-chevron-left"><i class="fal fa-chevron-left"></i></a>
            </div>
            <div class="row themes-wrapper themes-carousel">
              <div class="col col-33 col-m-50" *ngFor="let theme of resultNotfoundThemes">
                <a [style.background-image]="'url('+ theme.picture_url + ')'"
                   style="background-repeat: no-repeat; background-size: cover"
                   [routerLink]="theme.linkLibrary()"
                   class="home-theme-wrapper">
                      <span class="library-not-found-theme-name"
                            [style.background-color]="theme.color_code"> {{translationService.getTranslatedName(theme['translations'], locale)}}</span>
                </a>
              </div>
            </div>
            <div class="slick-slider-next-right slick-slider-arrow right-themes">
              <a class="icon-chevron-right"><i class="fal fa-chevron-right"></i></a>
            </div>
          </div>
        </section>
      </div>
      <app-loader *ngIf="showCoursesLoading && currentTab === 'courses'"></app-loader>

      <div class="library-sort" *ngIf="currentTab === 'lectures'">
        <h6>{{'academy.sort_by'|translate}}</h6>


        <div class="custom-control custom-radio mr-sm-2 mb-sm-0">
          <input type="radio" name="sortc" id="date" class="custom-control-input"
                 [checked]="currentMediasIndex === 'medias'"
                 (change)="onSortMediasChange('medias', $event.target.checked)">
          <label class="custom-control-label" for="date">{{'academy.Date'|translate}}</label>
        </div>
        <div class="custom-control custom-radio mr-sm-2 mb-sm-0">
          <input type="radio" name="sortc" id="rating" class="custom-control-input"
                 [checked]="currentMediasIndex === 'medias_relevance_rating'"
                 (change)="onSortMediasChange('medias_relevance_rating', $event.target.checked)">
          <label class="custom-control-label" for="rating">{{'academy.Rating'|translate}}</label>
        </div>
      </div>
      <div class="library-courses" *ngIf="currentTab === 'lectures' && medias.length > 0" infiniteScroll
           [infiniteScrollDistance]="-0.1" [infiniteScrollThrottle]="300" (scrolled)="onMediasScroll()">
        <div class="row">
          <div class="item col-6 col-md-4" [style]="environment.mobile ? {'padding-left': '5px', 'padding-right': '5px'}: {}" *ngFor="let mItem of medias">
            <app-lecture [card]="true" [lecture]="mItem" [user]="user"></app-lecture>
          </div>
        </div>
      </div>
      <div class="library-load-more"
           *ngIf="!showMediasLoading && currentTab === 'lectures' && medias.length > 0 && currentMediasPage < mediasPages">
        <a class="library-load-more-button"
           (click)="autoscroll = !autoscroll; onMediasScroll()">{{'academy.load_more'|translate}}
          <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
      </div>
      <!--  browse the library  -->
      <div class="library-not-found"
           *ngIf="!showMediasLoading && currentTab === 'lectures' && medias.length === 0">
        <i class="fa fa-search"></i>
        <h2>{{'academy.No_result_found'|translate}} </h2>
<!--        <a (click)="resetSearch()">{{'academy.Reset_search'|translate}}</a>-->
        <div class="browse-the-library">
          <section class="library-not-found-section">
            <div class="library-not-found-content">
                <h2>
                  <a [routerLink]="'/library/courses'">{{ 'academy.Browse_through'|translate }}<i
                  class="fal fa-chevron-right"></i></a>
                </h2>
                <div class="slick-slider-previous-left slick-slider-arrow left-themes">
                  <a class="icon-chevron-left"><i class="fal fa-chevron-left"></i></a>
                </div>
                <div class="row themes-wrapper themes-carousel">
                  <div class="col col-33 col-m-50" *ngFor="let theme of resultNotfoundThemes">
                    <a [style.background-image]="'url('+ theme.picture_url + ')'"
                       style="background-repeat: no-repeat; background-size: cover"
                       [routerLink]="theme.linkLibrary()"
                       class="home-theme-wrapper">
                      <span class="library-not-found-theme-name"
                            [style.background-color]="theme.color_code"> {{translationService.getTranslatedName(theme['translations'], locale)}}</span>
                    </a>
                  </div>
                </div>
                <div class="slick-slider-next-right slick-slider-arrow right-themes">
                  <a class="icon-chevron-right"><i class="fal fa-chevron-right"></i></a>
                </div>
            </div>
          </section>
        </div>
      </div>
      <app-loader *ngIf="showMediasLoading && currentTab === 'lectures'"></app-loader>
    </div>
    <div>
      <app-loader *ngIf="loadingUser"></app-loader>
    </div>
  </div>
</div>
