import {environment} from './../../../environments/environment';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MediaService} from 'app/services/media.service';
import {Media} from '../../models/media.model';
import {HttpClient} from '@angular/common/http';
import {timer} from 'rxjs/index';
import {Capacitor} from "@capacitor/core";
import {ScreenOrientation} from "@capacitor/screen-orientation";

declare const jwplayer: any;


@Component({
  selector: 'app-jwplayer-video',
  templateUrl: './jwplayer-video.component.html',
  styleUrls: ['./jwplayer-video.component.scss']
})
export class JwplayerVideoComponent implements OnChanges, OnDestroy, OnInit {
  private timeViewed: number = 0;
  private trueViewed: boolean = false;
  private startInterval: any;
  private playInterval: any;
  public showDisclaimer: boolean;
  public showIntro: boolean;
  public showSpeakers: boolean;
  public isFirstPlay: boolean = true;
  public showSponsoredLogo: boolean;
  private _playerInstance: any = null;
  private _audioTracks: any = [];
  private mediaService: any;
  public state: string = '';
  private playerLoaded: boolean = false;
  private replayCount: number = 0;
  @Input() language: any;
  @Input() languages: any[];
  @Input() file: string;
  @Input() file2: string;
  @Input() width: string;
  @Input() aspectratio: string;
  @Input() image: string;
  @Input() autoload: boolean = false;
  @Input() sponsored: boolean | number;
  @Input() mediaId: number;
  @Input() media: Media;
  @Input() withAds: boolean = false;
  @Input() adsType: string = '';
  @Input() adsEntity: string = '';
  @Input() adsId: number = null;
  @Input() mute: boolean = false;
  @Input() controls: boolean = true;
  @Input() replay: boolean = false;
  @Input() replayMax: number = 2;
  @Input() id: number = 0;
  @Output() audioChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() truelyViewed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() buffering: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() progress: EventEmitter<number> = new EventEmitter<number>();
  @Output() fixed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public playerEvent: EventEmitter<any> = new EventEmitter();
  public events: Array<string> = ['ready', 'play', 'pause', 'complete', 'seek', 'error', 'playlistItem', 'time', 'firstFrame'];

  constructor(private _elementRef: ElementRef, private httpClient: HttpClient) {
    jwplayer.key = 'flhnrqS9SvvQbMp1gwJrWTph9tet5nzJpmfHvMW8aSQ=';
  }

  ngOnInit() {
    this.mediaService = new MediaService(this.httpClient);
    if (!this.playerLoaded) {
      this.loadPlayerInstance();
    }
  }

  getPlayerInstance(): any {
    if (!this._playerInstance) {
      this._playerInstance = jwplayer(this._elementRef.nativeElement);
    }
    return this._playerInstance;

  }

  getState(): string {
    this.state = this.getPlayerInstance().getState();
    return this.state;
  }

  setState(state: string) {
    this.state = state;
  }

  getPosition(): number {
    return this.getPlayerInstance().getPosition();
  }

  setShowDisclaimer(state: boolean) {
    this.showDisclaimer = state;
  }

  setShowSpeakers(state: boolean) {
    this.showSpeakers = state;
  }

  setShowIntro(state: boolean) {
    this.showIntro = state;
  }

  setShowSponsoredLogo(state: boolean) {
    this.showSponsoredLogo = state;
  }

  setMute(state: boolean) {
    const instance = this.getPlayerInstance();
    this.mute = state;
    instance.setMute(this.mute);
  }

  loadPlayerInstance() {
    let setup;
    if (!this.file) {
      return null;
    }

    if (this.withAds) {
      setup = {
        aspectratio: this.aspectratio,
        image: this.image,
        preload: 'auto',
        width: this.width,
        autostart: this.autoload,
        file: this.file,
        playbackRateControls: [0.75, 1, 1.25, 1.5],
        mute: this.mute,
        controls: this.controls,
        // Kev - To fix player fail to load error
        // https://www.notion.so/by-us/Academy-player-Fail-to-load-error-10477d06fabe80d7be65fd8b2d24ae19?pvs=4
        // We can upgrade the JWPlayer version here
        // We specify the base param here to avoid ios error (// instead of https://)
        // More info : https://github.com/jwplayer/jwplayer/issues/3561
        base: 'https://ssl.p.jwpcdn.com/player/v/8.36.3/',
        advertising: {
          client: 'vast',
          adscheduleid: 'ads-' + this.adsType + '-' + this.adsEntity + '-' + this.adsId,
          schedule: [
            {
              offset: 'pre',
              tag: environment.base_url + '/video-ad/' + this.adsType + '/' + this.adsEntity + '/' + this.adsId + '.xml'
            }
          ]
        }
      };
    } else {
      setup = {
        aspectratio: this.aspectratio,
        image: this.image,
        preload: 'auto',
        width: this.width,
        autostart: this.autoload,
        // Kev - To fix player fail to load error
        // https://www.notion.so/by-us/Academy-player-Fail-to-load-error-10477d06fabe80d7be65fd8b2d24ae19?pvs=4
        // We can upgrade the JWPlayer version here
        // We specify the base param here to avoid ios error (// instead of https://)
        // More info : https://github.com/jwplayer/jwplayer/issues/3561
        base: 'https://ssl.p.jwpcdn.com/player/v/8.36.3/',
        file: this.file,
        playbackRateControls: [0.75, 1, 1.25, 1.5],
        mute: this.mute,
        controls: this.controls,
      };
    }

    const instance = this.getPlayerInstance();
    instance.setup(setup);
    const that = this;
    instance.on('ready', (e) => {
      this.changeLanguage(this.language);
      this.playerEvent.emit('ready');
     $('.jw-controls-backdrop').css({
        background: 'url(' + environment.cdn_base_url + '/assets-front/img/video-mask.png) repeat-x',
        'background-size': 'cover'
      });
     if(environment.mobile && Capacitor.getPlatform() !== 'web'){
       $('.jw-controls > .jw-controlbar > .jw-button-container > .jw-icon-pip').css({
         width: '0px'
       });
     }
    });
    instance.on('play', (e) => {
      if (this.media || this.mediaId) {
        const cdn_file = !this.media.cdn_file ? this.file2 : this.media.cdn_file;
        const fullVideo = this.media && !cdn_file.includes('30s');
        this.mediaService.addMediaView(this.media ? this.media.id : this.mediaId, fullVideo).subscribe();
        this.isFirstPlay = false;
        this.changeLanguage(this.language);
      }
      this.buffering.emit(false);
      this.playInterval = timer(10000, 10000)
        .subscribe(() => {
          this.progress.emit(Math.round(this.getPosition()));
          this.timeViewed = this.timeViewed + 10;
          if (this.timeViewed > this.getPlayerInstance().getDuration() / 2 && !this.trueViewed) {
            this.trueViewed = true;
            this.truelyViewed.emit(true);
          }
        });
      $('.jw-controls-backdrop').css({
        background: 'none'
      });
      this.setState(this.getState());
    });

    instance.on('pause', (e) => {
      this.clearTimer();
      this.progress.emit(Math.round(this.getPosition()));
      this.buffering.emit(false);
      this.setState(this.getState());
    });

    instance.on('buffer', (e) => {
      this.clearTimer();
      this.buffering.emit(true);
      this.progress.emit(Math.round(this.getPosition()));
      this.setState(this.getState());
      $('.jw-controls-backdrop').css({
        background: 'url(' + environment.cdn_base_url + '/assets-front/img/video-mask.png) repeat-x',
        'background-size': 'cover'
      });
    });

    instance.on('stop', (e) => {
      this.clearTimer();
      this.buffering.emit(false);
      this.setState(this.getState());
      this.progress.emit(Math.round(this.getPosition()));
      $('.jw-controls-backdrop').css({
        background: 'url(' + environment.cdn_base_url + '/assets-front/img/video-mask.png) repeat-x',
        'background-size': 'cover'
      });
    });
    instance.on('error', (e) => {
      this.clearTimer();
      this.buffering.emit(false);
      this.setState(this.getState());
      $('.jw-controls-backdrop').css({
        background: 'url(' + environment.cdn_base_url + '/assets-front/img/video-mask.png) repeat-x',
        'background-size': 'cover'
      });
    });

    // We want to be able to set the player to replay automatically (max times set in private prop)
    instance.on('complete', (e) => {
      if (!this.replay || this.replayCount >= this.replayMax) {
        this.buffering.emit(false);
        this.progress.emit(Math.round(this.getPosition()));
        this.completed.emit(true);
        if (this.media.intro_html) {
          this.setShowIntro(true);
          this.startInterval = timer(4080)
            .subscribe(() => {
              this.setShowIntro(false);
              this.clearTimer();
            });
        } else {
          this.clearTimer();
        }
        this.setState(this.getState());
        $('.jw-controls-backdrop').css({
          background: 'url(' + environment.cdn_base_url + '/assets-front/img/video-mask.png) repeat-x',
          'background-size': 'cover'
        });
      } else if (this.replay && (this.replayCount < this.replayMax)) {
        instance.seek(0);
        instance.play();
        this.replayCount++;
      }
    });
    instance.on('audioTrackChanged', (event) => {
      this.language = event.tracks[event.currentTrack].language;
      this.audioChanged.emit(this.language);
    });

    $(window).on('scroll', () => {
      if ($('.video-wrapper')[0]) {
        const docViewTop = $(window).scrollTop();
        const elemTop = $('.video-wrapper').offset().top;
        const elemBottom = elemTop + $('.video-wrapper').height();
        if ($(window).height() < 800) {
          this.fixed.emit(false);
        }
        this.fixed.emit(elemBottom < docViewTop);
      } else {
        $(window).off('scroll');
      }
    });

    if (environment.mobile && Capacitor.getPlatform() !== 'web') {
      instance.on('fullscreen', event => {
        console.log('fullscreen', JSON.stringify(event));
        if (event.fullscreen) {
          ScreenOrientation.lock({orientation: 'landscape'}).then(() => {
              $('.jw-controls > .jw-controlbar > .jw-button-container').css({
                'padding-right': 'calc(10px + var(--safe-area-top))',
              });
          });
        } else {
          ScreenOrientation.lock({orientation: "portrait"}).then(() => {
            $('.jw-controls > .jw-controlbar > .jw-button-container').css({
              'padding-right': '12px',
            });
          }).catch(() =>  {})
        }
      })
    }
  }

  changeLanguage(locale) {
    let locale2;
    if (locale === 'en') {
      locale2 = 'eng';
    } else if (locale === 'es') {
      locale2 = 'spa';
    } else if (locale === 'it') {
      locale2 = 'ita';
    } else if (locale === 'fr') {
      locale2 = 'fra';
    } else if (locale === 'ru') {
      locale2 = 'rus';
    } else if (locale === 'zh') {
      locale2 = 'zho';
    } else if (locale === 'pt') {
      locale2 = 'por';
    } else if(locale === 'th') {
      locale2 = 'tha';
    } else {
      locale2 = false;
    }
    if (this.getPlayerInstance()) {
      let selectedIndex;
      this._audioTracks = this.getPlayerInstance().getAudioTracks();
      if (this._audioTracks && this._audioTracks.length > 0) {
        for (const index in this._audioTracks) {
          if (this._audioTracks[index].language.indexOf(locale) !== -1 ||
            this._audioTracks[index].language.indexOf(locale2) !== -1) {
            selectedIndex = index;
          }
        }
      } else if (this.languages && this.languages.length > 1) {
        selectedIndex = this.languages.indexOf(locale);
        this.language = this.languages[selectedIndex];
      }
      if (selectedIndex) {
        this.getPlayerInstance().setCurrentAudioTrack(selectedIndex);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.playerLoaded = true;
    for (const p in changes) {
      if (changes.hasOwnProperty(p)) {
        if (p === 'language') {
          this.changeLanguage(changes[p].currentValue);
        }
        if (this.width && this.aspectratio && p === 'file' && changes[p].currentValue && !changes[p].previousValue) {
          this.loadPlayerInstance()
        }
      }

    }
  }


  ngOnDestroy() {
    this.getPlayerInstance().remove();
    this.clearTimer();
  }

  public start() {
    if (this.isFirstPlay === true) {
      if (this.sponsored) {
        this.setShowDisclaimer(true);
        this.startInterval = timer(3000).subscribe(() => {
          this.setShowDisclaimer(false);
          this.setShowSponsoredLogo(true);
          this.clearTimer();
          this.startInterval = timer(3000).subscribe(() => {
            this.setShowSponsoredLogo(false);
            this.getPlayerInstance().play();
            this.setState(this.getState());
            this.clearTimer();
            const cdn_file = !this.media.cdn_file ? this.file2 : this.media.cdn_file;
            const fullVideo = this.media && !cdn_file.includes('30s');
            this.mediaService.addMediaView(this.media ? this.media.id : this.mediaId, fullVideo).subscribe();
            this.isFirstPlay = false;
          });
        });
      } else {
        if (this.media.intro_html) {
          this.setShowIntro(true);
          if (this.media.speakers && this.media.speakers.length > 3) {
            this.setShowIntro(false);
            this.getPlayerInstance().play();
            this.setState(this.getState());
            this.isFirstPlay = false;
          } else {
            this.startInterval = timer(4080).subscribe(() => {
              this.setShowIntro(false);
              this.setShowSpeakers(true);
              this.clearTimer();
              this.startInterval = timer(4000).subscribe(() => {
                this.clearTimer();
                this.setShowSpeakers(false);
                this.getPlayerInstance().play();
                this.setState(this.getState());
                this.isFirstPlay = false;
              });
            });
          }
        } else {
          this.getPlayerInstance().play();
          this.setState(this.getState());
        }
      }
    } else {
      this.getPlayerInstance().play();
      this.setState(this.getState());
    }
  };

  clearTimer() {
    if (this.startInterval) {
      this.startInterval.unsubscribe();
    }
    if (this.playInterval) {
      this.playInterval.unsubscribe();
    }
  }
}
