import {WebinarService} from '../../../services/webinar.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/user.model';
import {ThreadService} from '../../../services/thread.service';
import {NotificationService} from '../../../services/notification.service';
import {UserService} from '../../../services/user.service';
import {LocaleService} from '../../../services/locale.service';
import {DateMomentService} from '../../../services/date-moment.service';
import {PusherService} from '../../../services/pusher.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StartingWebinarComponent} from '../../modal/starting-webinar/starting-webinar.component';
import {Router} from '@angular/router';
import {Webinar} from '../../../models/webinar.model';
import {environment} from "../../../../environments/environment";

declare var gtag;


@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
  providers: [UserService, WebinarService, PusherService]
})
export class HeaderProfileComponent implements OnInit {
  @Input() user: User;
  @Output() loggedOutClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() webinarTodayBanner: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() webinarToday: EventEmitter<Webinar> = new EventEmitter<Webinar>();
  @Output() menuClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  alertUnread: any = {};
  notifications: any = false;
  messageUnreadCount: number;
  logged: any;
  environment = environment;

  constructor(public authService: AuthService,
              private threadService: ThreadService,
              private notificationService: NotificationService,
              private localeService: LocaleService,
              private webinarService: WebinarService,
              private userService: UserService,
              private dateMomentService: DateMomentService,
              private pusherService: PusherService,
              private modalService: NgbModal,
              private router: Router) {

  }

  logout() {
    this.loggedOutClick.emit(true);
  }

  menuClick() {
    this.menuClicked.emit(true);
  }

  goToLogin() {
    this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url}})
  }

  clickSubscribe() {
   /* gtag('event', 'click-subscribe-btn-connected');*/
  }

  ngOnInit() {
    this.logged = false;
    this.threadService.observeMessagesUnreadCount().subscribe(messageUnreadCount => {
      this.messageUnreadCount = messageUnreadCount;
    });
    this.authService.isLoggedIn().subscribe(value => {
      this.logged = value;
      if (value) {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
          this.userService.getUser(this.user.id).subscribe((user) => {
            if (!user.email) {
              this.logoutDirectly('Unauthenticated.')
            } else {
              if (this.user) {
                this.user = Object.assign(this.user, user);
              }
              this.authService.setCurrentUser(this.user);
              if (this.authService.hasToken()) {
                this.threadService.getMessagesUnreadCount();
                this.setAlertUnread();
                this.notificationService.getNotifications().subscribe(
                  notifications => {
                    this.notifications = notifications;
                  });
              }
            }
          }, response => {
            if (response.error) {
              this.logoutDirectly(response.error.message)
            }
          });
        }
      }
    });
    this.webinarService.getNextWebinar().subscribe(res => {
      if (res) {
        this.webinarToday.emit(res)
        let hours = +res.datetime_when_in_hours;

        if (hours > 0 && hours < 2) {
          this.pusherService.statusStream$
            .subscribe(newStatus => {
              if (newStatus.status === 'launched') {
                this.modalService.open(StartingWebinarComponent).result.then((goTo) => {
                  if (goTo) {
                    this.router.navigateByUrl('/webinar');
                  }
                });
              }
            });
        }
      }
    });
  }

  logoutDirectly(message) {
    if (message === 'Unauthenticated.') {
      this.authService.setCurrentUser(null);
      this.authService.logout();
      this.user = null;
    }

  }

  setAlertUnread() {
    this.notificationService.getNewNotifications().subscribe(
      notifications => {
        this.alertUnread = notifications;
      });
  }
}
